var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-uploader" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          attrs: {
            accept: ".xls, .xlsx, .pdf, .doc, .docx",
            "file-list": _vm.fileList,
            "auto-upload": true,
            "show-file-list": false,
            "on-change": _vm.handleChange,
            "on-success": _vm.successHandler,
            "on-error": _vm.errorHandler,
            "before-upload": _vm.beforeUpload,
            action: _vm.uploadPathBuild(),
          },
        },
        [
          _c("r-button", [
            _c("i", { staticClass: "el-icon-paperclip upload-icon" }),
            _vm._v("\n      点击上传\n    "),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        _vm._l(_vm.fileList, function (item, index) {
          return _c("el-col", { key: item.uid, attrs: { span: 12 } }, [
            _c("div", { staticClass: "file-list card-container" }, [
              _c("div", { staticClass: "top-line" }, [
                _c("span", { staticClass: "name-span" }, [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/images/workflow/fileTypeIcon/${_vm.iconRender(
                        item
                      )}.png`),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "size-part" }, [
                    _c("div", { staticClass: "name-line" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.attachmentName || item.name) +
                          "\n              "
                      ),
                    ]),
                    _c("div", { staticClass: "size-line" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.sizeRender(item)) +
                          "\n              "
                      ),
                    ]),
                  ]),
                ]),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      return _vm.deleteHandler(item)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "progress-line" }, [
                _c("div", { staticClass: "outer" }, [
                  _c("div", {
                    staticClass: "inner",
                    style: {
                      width:
                        index === _vm.fileList.length - 1
                          ? `${_vm.progressRate}%`
                          : "100%",
                    },
                  }),
                ]),
                _c("div", { staticClass: "rate-part" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        index === _vm.fileList.length - 1
                          ? _vm.progressRate
                          : "100"
                      ) +
                      "%\n          "
                  ),
                ]),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="process-handing-component">
    <ProcessTable
      class="handing-table"
      v-loading="tableLoading"
      v-if="itemRender('processTable')"
      :tableHeader="handingTableHeader"
      :tableData="handingTableData"
    />
    <div class="detail-line" v-if="itemRender('currentProcessor')">
      <span class="line-label">当前处理人:</span>
      <span class="line-value">{{ processInfo.currentProcessor || "-" }}</span>
    </div>
    <div class="detail-line" v-if="itemRender('processedPerson')">
      <span class="line-label">已经处理人:</span>
      <span class="line-value">{{ processInfo.handleProcessor || "-" }}</span>
    </div>
    <div class="detail-line" v-if="itemRender('operation')">
      <div class="line-title">操作</div>
      <ProcessRadio :dataset="operationRadioList.filter((item) => item.show)" />
    </div>
    <div class="submit-role detail-line" v-if="itemRender('submitRole')">
      <div class="line-title">提交身份</div>
      <SubmitRoleSelector />
    </div>
    <div class="detail-line" v-if="itemRender('reject')">
      <div class="line-title">驳回到</div>
      <RejectNodeSelector @update="rejectUpdate" :flowDetails="flowDetails" />
    </div>
    <div class="detail-line" v-if="itemRender('takeBackPerson')">
      <div class="line-title">回收人员</div>
      <TakeBackPerson @update="takeBackUpdate" :flowDetails="flowDetails" />
    </div>
    <div class="detail-line" v-if="itemRender('transfer')">
      <div class="line-title">转办人员</div>
      <TransferUserSelector @update="transferUpdate" />
      <el-checkbox class="inner-checkbox" v-model="reflowHandledByTransferrer"
        >流程重新流经本节点时，直接由转办人员处理</el-checkbox
      >
    </div>
    <div class="detail-line" v-if="itemRender('countersign')">
      <div class="line-title">加签人员</div>
      <CountersignUserSelector @update="updateSign" />
      <el-checkbox class="inner-checkbox" v-model="allowMultilevelSign"
        >允许多级加签</el-checkbox
      >
    </div>
    <el-row>
      <el-col :span="12">
        <div class="flow-to detail-line" v-if="itemRender('notifyMethod')">
          <div class="line-title">
            通知方式
            <!-- <span class="next-processor" v-show="processInfo.nextProcessor">
              {{ `（即将流向:${processInfo.nextProcessor}）` }}
            </span> -->
            (
            <span
              class="next-processor"
              v-for="item in nextStepList"
              :key="item.nextNode"
            >
              <span class="nextNode-span"
                >即将流向节点: {{ item.nextNode }}</span
              >;
              <span class="processor-span" v-if="item.nextProcessor"
                >[审批人:{{ item.nextProcessor || "暂时无法计算处理人" }}]</span
              >
            </span>
            )
          </div>
          <el-checkbox-group v-model="flowCheckList">
            <el-checkbox :label="0" disabled>待办</el-checkbox>
            <el-checkbox :label="1">短信</el-checkbox>
            <el-checkbox :label="2">邮件</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="notice-line detail-line" v-if="itemRender('NotifyUrgency')">
          <div class="line-title">通知紧急程度</div>
          <ProcessRadio :dataset="noticeRadioList" />
        </div>
      </el-col>
    </el-row>
    <div class="detail-line" v-if="itemRender('notifyAfter')">
      <div class="line-title">通知选项</div>
      <el-checkbox v-model="isEndNotify">流程结束后通知我</el-checkbox>
    </div>
    <div class="detail-line" v-if="itemRender('uploadFile')">
      <div class="line-title">上传附件</div>
      <ProcessUploader
        @update="uploaderChange"
        :dataset="workflowAttachmentVOList"
      />
    </div>
    <div class="comments-line detail-line" v-if="itemRender('comments')">
      <div class="line-title">处理意见</div>
      <el-input
        class="comments-input"
        type="textarea"
        ref="commentsInputIns"
        :autosize="{ minRows: 4, maxRows: 6 }"
        placeholder="请输入处理意见"
        v-model="auditComment"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
import SubmitRoleSelector from "./submitRoleSelector";
import RejectNodeSelector from "./rejectNodeSelector";
import TransferUserSelector from "./transferUserSelector";
import CountersignUserSelector from "./countersignUserSelector";
import TakeBackPerson from "./takeBackPerson";
import ProcessRadio from "./processRadio";
import ProcessTable from "./processTable";
import ProcessUploader from "./processUploader";
import {
  getAuditeLogByDefinitionId,
  queryToNode,
  queryBasedOnReviewerType,
} from "@/api/ruge/workflow/detail";
import { dateFormat } from "@/filters/index.js";

export default {
  name: "process-handing-component",
  components: {
    SubmitRoleSelector,
    RejectNodeSelector,
    TransferUserSelector,
    TakeBackPerson,
    CountersignUserSelector,
    ProcessRadio,
    ProcessTable,
    ProcessUploader,
  },
  props: {
    /**
     * processStatus: (0:草稿: 1:待审:2:驳回: 3:撤回 4 :结束)
     */
    processStatus: {
      type: Number,
      default() {
        return 0;
      },
    },
    state: {
      type: String,
      default() {
        return "";
      },
    },
    diaplayDatas: {
      type: Object,
      default() {
        return {
          auditComment: null,
          notificationType: null,
          urgencyLevel: null,
        };
      },
    },
    processInfo: {
      type: Object,
      default() {
        return {
          currentProcessor: "-",
          handleProcessor: "-",
        };
      },
    },
    queryNodeParams: {
      type: Object,
      default() {
        return {
          definitionId: null, //"流程实例ID",
          nodeId: null, //"当前节点ID",
          modeId: null, //"模型ID",
          versionId: null, //"流程版本ID",
          formData: null, //"form表单数据",
        };
      },
    },
    flowDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    buttonList: {
      type: Array,
      default() {
        return [1, 2, 3, 4];
      },
    },
  },
  data() {
    return {
      nextStepList: [
        // {
        //   nextNode: "审批A",
        //   nextProcessor: "刘德华",
        // },
        // {
        //   nextNode: "审批B",
        //   nextProcessor: "张学友",
        // },
      ],
      tableLoading: false,
      handingTableHeader: [
        {
          label: "时间",
          key: "taskEndTime",
          flex: 1,
        },
        {
          label: "节点名称",
          key: "nodeName",
          flex: 1,
        },
        {
          label: "操作者",
          key: "reviewerName",
          flex: 1,
        },
        {
          label: "操作",
          key: "systemOperate",
          flex: 1,
        },
        {
          label: "处理意见",
          key: "operate",
          flex: 3,
        },
        {
          label: "时长",
          key: "duration",
          flex: 1,
        },
        {
          label: "附件",
          key: "attachmentVOList",
          flex: 2,
        },
      ],
      handingTableData: [],
      isEndNotify: true, //流程结束是否通知我（Y/N）
      reflowHandledByTransferrer: true, //流程重新流经本节点时，直接由转办人员处理
      allowMultilevelSign: true,
      auditComment: null,
      flowCheckList: [0],
      noticeRadioList: [
        {
          label: "一般",
          active: true,
          icon: require("@/assets/images/workflow/detail/normal-icon.png"),
          key: "normal",
          paramsKey: 0,
        },
        {
          label: "急",
          active: false,
          icon: require("@/assets/images/workflow/detail/impatient-icon.png"),
          key: "impatient",
          paramsKey: 1,
        },
        {
          label: "紧急",
          active: false,
          icon: require("@/assets/images/workflow/detail/emergency-icon.png"),
          key: "emergency",
          paramsKey: 2,
        },
      ],
      operationRadioList: [
        {
          label: "通过",
          active: true,
          key: "pass",
          paramKey: 1,
          show: true,
        },
        {
          label: "驳回",
          active: false,
          key: "reject",
          paramKey: 2,
          show: true,
        },
        {
          label: "转办",
          active: false,
          key: "transfer",
          paramKey: 3,
          show: true,
        },
        {
          label: "退回",
          active: false,
          key: "return",
          paramKey: 5,
          show: true,
        },
        {
          label: "加签",
          active: false,
          key: "countersign",
          paramKey: 4,
          show: true,
        },
        {
          label: "收回",
          active: false,
          key: "takeBack",
          paramKey: 6,
          show: true,
        },
      ],
      workflowAttachmentVOList: [], // 上传附件数据
      rejectNodeId: null, //驳回节点
      recipient: null, //转办接收人
      recipientList: [], //加签接收人
      takeBackList: [], //收回接收人
    };
  },
  watch: {
    diaplayDatas: {
      handler(datas) {
        const {
          notificationType,
          auditComment,
          urgencyLevel,
          workflowAttachmentVOList,
        } = datas;
        this.flowCheckList = notificationType
          ? notificationType.split(",").map((item) => Number(item))
          : [0];
        this.auditComment = auditComment;
        if (!urgencyLevel) return;
        this.noticeRadioList.forEach((item) => {
          item.active = item.paramsKey === urgencyLevel;
        });
        this.workflowAttachmentVOList = workflowAttachmentVOList || [];
      },
      immediate: true,
    },
    queryNodeParams: {
      handler(params) {
        const { modeId, versionId, formData } = params;
        if (modeId && versionId && formData) {
          this.initNextNode();
        }
      },
      immediate: true,
    },
    buttonList: {
      handler(list) {
        this.operationRadioList.forEach((item) => {
          item.show = list.includes(item.paramKey);
          item.active = list[0] === item.paramKey;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    currentOperation() {
      return this.operationRadioList.filter((item) => item.active)[0].key;
    },
    // operationRadioListFiltered() {
    //   const temp = this.operationRadioList.filter((item) =>
    //     this.buttonList.includes(item.paramKey)
    //   );
    //   return
    // },
  },
  created() {
    this.initScene();
  },
  methods: {
    async initNextNode() {
      /**
       * nextNode: '审批A',
         nextProcessor: '刘德华',
       */
      const nodeList = await queryToNode(this.queryNodeParams);
      if (nodeList && nodeList.length) {
        let requestList = nodeList.map((item) =>
          this.getNextProcessorByNodeId(item.nodeId)
        );
        const nodeProcessorList = await Promise.all(requestList);
        let result = [];
        nodeList.forEach((item, index) => {
          result.push({
            nextNode: item.nodeName,
            nextProcessor: nodeProcessorList[index]
              .map((single) => {
                return single.userName;
              })
              .join(";"),
          });
        });
        this.nextStepList = result;
      }
    },
    getNextProcessorByNodeId(nodeId) {
      const { definitionId } = this.$route.query;
      return new Promise((resolve) => {
        queryBasedOnReviewerType({ nodeId, definitionId }).then((res) => {
          resolve(res);
        });
      });
    },
    rejectUpdate(nodeId) {
      this.rejectNodeId = nodeId;
    },
    takeBackUpdate(userList) {
      this.takeBackList = userList;
    },
    transferUpdate(userId) {
      console.log("转审ID：", userId);
      this.recipient = userId;
    },
    updateSign(userIdList) {
      this.recipientList = userIdList;
    },
    uploaderChange(fileList) {
      this.workflowAttachmentVOList = fileList.map((item) => {
        const { displayName, fileId, fileSize } = item.response[0];
        return {
          attachmentName: displayName,
          fileId,
          fileSize,
        };
      });
    },
    initScene() {
      const { definitionId } = this.$route.query;
      // 流程处理 - table数据获取
      this.tableLoading = true;
      getAuditeLogByDefinitionId({
        definitionId,
      })
        .then((res) => {
          this.tableData = [];
          res.forEach((item) => {
            let lineData = [];
            item.duration = this.addElapsedTime(item);
            this.handingTableHeader.forEach((single) => {
              lineData.push({
                ...single,
                ...{
                  value: ["taskEndTime"].includes(single.key)
                    ? dateFormat(item[single.key], "YYYY年MM月DD日 HH:mm:ss")
                    : item[single.key] || "-",
                  key: single.key,
                },
              });
            });
            this.handingTableData.push(lineData);
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addElapsedTime({ elapsedTime }) {
      let result = "";
      if (elapsedTime) {
        result = `${this.convertMilliseconds(elapsedTime)}`;
      }
      return result;
    },
    convertMilliseconds(milliseconds) {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      // 计算剩余的分钟数
      minutes = minutes % 60;
      // 计算剩余的小时数
      hours = hours % 24;
      // 计算剩余的秒
      seconds = seconds % 60;
      let str = "";
      if (days > 0) {
        str += `${days}天`;
      }
      if (hours > 0) {
        str += `${hours}小时`;
      }
      if (minutes > 0) {
        str += `${minutes}分钟`;
      }
      if (seconds > 0) {
        str += `${seconds}秒`;
      }
      return str.trim();
    },
    itemRender(itemName) {
      switch (itemName) {
        case "submitRole":
          return !this.state || this.state === "draft";
        case "operation":
        case "notifyAfter":
        case "uploadFile":
          return this.state === "process";
        case "processTable":
        case "currentProcessor":
        case "processedPerson":
          return (
            this.state === "process" ||
            this.state === "readOnly" ||
            [2, 3].includes(this.processStatus)
          );
        case "reject":
          return this.state === "process" && this.currentOperation === "reject";
        case "takeBackPerson":
          return (
            this.state === "process" && this.currentOperation === "takeBack"
          );
        case "transfer":
          return (
            this.state === "process" && this.currentOperation === "transfer"
          );
        case "countersign":
          return (
            this.state === "process" && this.currentOperation === "countersign"
          );
        case "notifyMethod":
        case "NotifyUrgency":
        case "comments":
          return this.state !== "readOnly";
      }
    },
    getSubmitParams(state) {
      if (!this.checkRequire(state)) {
        return false;
      }
      const { versionId, definitionId } = this.$route.query;
      const {
        auditComment,
        noticeRadioList,
        flowCheckList,
        workflowAttachmentVOList,
      } = this;
      if (!state) {
        // 提交
        return {
          versionId, //流程版本 ID
          // formData: "", //表单数据
          // formDataJson: "", //表单数据(解析过后的Json)
          auditComment, //审核意见
          urgencyLevel: noticeRadioList.filter((item) => item.active)[0]
            .paramsKey, //紧急程度，0：一般，1：急，2：紧急
          notificationTypeDetailList: flowCheckList, //通知类型，0:待办、1：消息、2：邮件
        };
      } else if (state === "draft") {
        // 草稿
        return {
          versionId,
          auditComment,
          urgencyLevel: noticeRadioList.filter((item) => item.active)[0]
            .paramsKey,
          notificationTypeDetailList: flowCheckList,
          workflowAttachmentVOList,
        };
      } else {
        //
        let params = {
          isEndNotify: this.isEndNotify ? "Y" : "N",
          auditOperation: this.operationRadioList.filter(
            (item) => item.active
          )[0].paramKey,
          // taskId: "", //任务id
          // nodeId: "", //节点id
          definitionId, //流程实例id
          // dataId: "", //表单数据id
          // formData: "", //表单数据
          // formDataJson: "", //表单数据(解析过后的Json)
          auditComment, //审核意见
          urgencyLevel: noticeRadioList.filter((item) => item.active)[0]
            .paramsKey,
          notificationTypeDetailList: flowCheckList, //通知类型，0:待办、1：消息、2：邮件
          workflowAttachmentVOList,
        };
        const result = this.addSpecialParams(params);
        return result;
      }
    },
    checkRequire(state) {
      // 暂存不需要做校验
      if (state === "draft") {
        return true;
      }
      if (!this.auditComment) {
        this.$message.warning("请填写处理意见！");
        try {
          const targetDom = this.$refs.commentsInputIns.$el;
          targetDom.scrollIntoView({ behavior: "smooth" });
          this.$refs.commentsInputIns.focus();
        } finally {
          return false;
        }
      }
      switch (this.currentOperation) {
        case "pass":
        case "return":
          return true;
        case "reject":
          !this.rejectNodeId && this.$message.warning("请选择拨回到节点");
          return this.rejectNodeId && this.rejectNodeId.length > 0;
        case "transfer":
          !this.recipient && this.$message.warning("请选择转办人员");
          return this.recipient !== null;
        case "countersign":
          !this.recipientList.length && this.$message.warning("请选择加签人员");
          return this.recipientList && this.recipientList.length > 0;
        case "takeBack":
          !this.takeBackList.length &&
            this.$message.warning("请选择收回接收人");
          return this.takeBackList && this.takeBackList.length > 0;
      }
    },
    addSpecialParams(params) {
      //审核操作（1：通过，2：驳回，3：转派，4：加签）
      const {
        rejectNodeId,
        recipient,
        reflowHandledByTransferrer,
        allowMultilevelSign,
        recipientList,
        takeBackList,
      } = this;
      switch (this.currentOperation) {
        case "pass":
        case "return":
          return params;
        case "reject":
          return {
            ...params,
            ...{
              rejectNodeId,
            },
          };
        case "transfer":
          return {
            ...params,
            ...{
              recipient,
              reflowHandledByTransferrer: reflowHandledByTransferrer
                ? "Y"
                : "N",
            },
          };
        case "countersign":
          return {
            ...params,
            ...{
              recipientList,
              allowMultilevelSign: allowMultilevelSign ? 1 : 0,
            },
          };
        case "takeBack":
          return {
            ...params,
            ...{
              userIdList: takeBackList,
            },
          };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.process-handing-component {
  .handing-table {
    margin-bottom: 30px;
  }
  .flow-to {
    .line-title {
      margin-bottom: 24px !important;
    }
  }
  .detail-line {
    margin-bottom: 30px;
    .inner-checkbox {
      margin-top: 18px;
    }
    .upload-icon {
      font-size: 16px;
      color: #2a61ff;
      margin-right: 4px;
    }
    .line-label {
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
    }
    .line-value {
      font-weight: 400;
      font-size: 14px;
      color: #252d3d;
    }
    .line-title {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      margin-bottom: 12px;
      .next-processor {
        font-weight: 600;
        font-size: 14px;
        color: #252d3d;
        .nextNode-span {
        }
        .processor-span {
          color: #dd772c;
        }
      }
    }
    .comments-input {
      width: 100%;
    }
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-handing-component" },
    [
      _vm.itemRender("processTable")
        ? _c("ProcessTable", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            staticClass: "handing-table",
            attrs: {
              tableHeader: _vm.handingTableHeader,
              tableData: _vm.handingTableData,
            },
          })
        : _vm._e(),
      _vm.itemRender("currentProcessor")
        ? _c("div", { staticClass: "detail-line" }, [
            _c("span", { staticClass: "line-label" }, [_vm._v("当前处理人:")]),
            _c("span", { staticClass: "line-value" }, [
              _vm._v(_vm._s(_vm.processInfo.currentProcessor || "-")),
            ]),
          ])
        : _vm._e(),
      _vm.itemRender("processedPerson")
        ? _c("div", { staticClass: "detail-line" }, [
            _c("span", { staticClass: "line-label" }, [_vm._v("已经处理人:")]),
            _c("span", { staticClass: "line-value" }, [
              _vm._v(_vm._s(_vm.processInfo.handleProcessor || "-")),
            ]),
          ])
        : _vm._e(),
      _vm.itemRender("operation")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("操作")]),
              _c("ProcessRadio", {
                attrs: {
                  dataset: _vm.operationRadioList.filter((item) => item.show),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("submitRole")
        ? _c(
            "div",
            { staticClass: "submit-role detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("提交身份")]),
              _c("SubmitRoleSelector"),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("reject")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("驳回到")]),
              _c("RejectNodeSelector", {
                attrs: { flowDetails: _vm.flowDetails },
                on: { update: _vm.rejectUpdate },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("takeBackPerson")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("回收人员")]),
              _c("TakeBackPerson", {
                attrs: { flowDetails: _vm.flowDetails },
                on: { update: _vm.takeBackUpdate },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("transfer")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("转办人员")]),
              _c("TransferUserSelector", {
                on: { update: _vm.transferUpdate },
              }),
              _c(
                "el-checkbox",
                {
                  staticClass: "inner-checkbox",
                  model: {
                    value: _vm.reflowHandledByTransferrer,
                    callback: function ($$v) {
                      _vm.reflowHandledByTransferrer = $$v
                    },
                    expression: "reflowHandledByTransferrer",
                  },
                },
                [_vm._v("流程重新流经本节点时，直接由转办人员处理")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("countersign")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("加签人员")]),
              _c("CountersignUserSelector", { on: { update: _vm.updateSign } }),
              _c(
                "el-checkbox",
                {
                  staticClass: "inner-checkbox",
                  model: {
                    value: _vm.allowMultilevelSign,
                    callback: function ($$v) {
                      _vm.allowMultilevelSign = $$v
                    },
                    expression: "allowMultilevelSign",
                  },
                },
                [_vm._v("允许多级加签")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm.itemRender("notifyMethod")
              ? _c(
                  "div",
                  { staticClass: "flow-to detail-line" },
                  [
                    _c(
                      "div",
                      { staticClass: "line-title" },
                      [
                        _vm._v("\n          通知方式\n          "),
                        _vm._v("\n          (\n          "),
                        _vm._l(_vm.nextStepList, function (item) {
                          return _c(
                            "span",
                            {
                              key: item.nextNode,
                              staticClass: "next-processor",
                            },
                            [
                              _c("span", { staticClass: "nextNode-span" }, [
                                _vm._v(
                                  "即将流向节点: " + _vm._s(item.nextNode)
                                ),
                              ]),
                              _vm._v(";\n            "),
                              item.nextProcessor
                                ? _c(
                                    "span",
                                    { staticClass: "processor-span" },
                                    [
                                      _vm._v(
                                        "[审批人:" +
                                          _vm._s(
                                            item.nextProcessor ||
                                              "暂时无法计算处理人"
                                          ) +
                                          "]"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v("\n          )\n        "),
                      ],
                      2
                    ),
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.flowCheckList,
                          callback: function ($$v) {
                            _vm.flowCheckList = $$v
                          },
                          expression: "flowCheckList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox",
                          { attrs: { label: 0, disabled: "" } },
                          [_vm._v("待办")]
                        ),
                        _c("el-checkbox", { attrs: { label: 1 } }, [
                          _vm._v("短信"),
                        ]),
                        _c("el-checkbox", { attrs: { label: 2 } }, [
                          _vm._v("邮件"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _vm.itemRender("NotifyUrgency")
              ? _c(
                  "div",
                  { staticClass: "notice-line detail-line" },
                  [
                    _c("div", { staticClass: "line-title" }, [
                      _vm._v("通知紧急程度"),
                    ]),
                    _c("ProcessRadio", {
                      attrs: { dataset: _vm.noticeRadioList },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.itemRender("notifyAfter")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("通知选项")]),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.isEndNotify,
                    callback: function ($$v) {
                      _vm.isEndNotify = $$v
                    },
                    expression: "isEndNotify",
                  },
                },
                [_vm._v("流程结束后通知我")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("uploadFile")
        ? _c(
            "div",
            { staticClass: "detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("上传附件")]),
              _c("ProcessUploader", {
                attrs: { dataset: _vm.workflowAttachmentVOList },
                on: { update: _vm.uploaderChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.itemRender("comments")
        ? _c(
            "div",
            { staticClass: "comments-line detail-line" },
            [
              _c("div", { staticClass: "line-title" }, [_vm._v("处理意见")]),
              _c("el-input", {
                ref: "commentsInputIns",
                staticClass: "comments-input",
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 6 },
                  placeholder: "请输入处理意见",
                },
                model: {
                  value: _vm.auditComment,
                  callback: function ($$v) {
                    _vm.auditComment = $$v
                  },
                  expression: "auditComment",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="process-uploader">
    <el-upload
      ref="upload"
      accept=".xls, .xlsx, .pdf, .doc, .docx"
      :file-list="fileList"
      :auto-upload="true"
      :show-file-list="false"
      :on-change="handleChange"
      :on-success="successHandler"
      :on-error="errorHandler"
      :before-upload="beforeUpload"
      :action="uploadPathBuild()"
    >
      <r-button>
        <i class="el-icon-paperclip upload-icon"></i>
        点击上传
      </r-button>
    </el-upload>
    <el-row :gutter="10">
      <el-col :span="12" v-for="(item, index) in fileList" :key="item.uid">
        <div class="file-list card-container">
          <div class="top-line">
            <span class="name-span">
              <img
                :src="
                  require(`@/assets/images/workflow/fileTypeIcon/${iconRender(
                    item
                  )}.png`)
                "
                alt=""
              />
              <div class="size-part">
                <div class="name-line">
                  {{ item.attachmentName || item.name }}
                </div>
                <div class="size-line">
                  {{ sizeRender(item) }}
                </div>
              </div>
            </span>
            <i class="el-icon-close" @click="deleteHandler(item)"></i>
          </div>
          <div class="progress-line">
            <div class="outer">
              <div
                class="inner"
                :style="{
                  width:
                    index === fileList.length - 1 ? `${progressRate}%` : '100%',
                }"
              ></div>
            </div>
            <div class="rate-part">
              {{ index === fileList.length - 1 ? progressRate : "100" }}%
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
export default {
  data() {
    return {
      uploadLoading: false,
      fileList: [],
      progressRate: 0,
    };
  },
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    dataset: {
      handler(list) {
        console.log("list", list);
        this.fileList = list;
        this.progressRate = 100;
      },
      immediate: true,
    },
  },
  methods: {
    progressAnimation() {
      this.progressRate = 0;
      setTimeout(() => {
        this.progressRate = 100;
      }, 200);
    },
    deleteHandler({ uid }) {
      this.fileList = this.fileList.filter((item) => item.uid !== uid);
      this.$emit("update", this.fileList);
    },
    sizeRender(item) {
      const size = item.fileSize || item.size;
      if (size > 1024 * 1024) {
        return `${(size / 1024 / 2014).toFixed()}MB`;
      }
      return `${(size / 1024).toFixed()}KB`;
    },
    iconRender(item) {
      const name = item.attachmentName || item.name;
      const lowerCaseFileName = name.toLowerCase();
      const fileExtension = lowerCaseFileName.split(".").pop();
      switch (fileExtension) {
        // case 'jpg':
        // case 'jpeg':
        //   return 'image/jpeg';
        // case 'png':
        //   return 'image/png';
        // case 'gif':
        // return 'image/gif';
        case "pdf":
          return "pdf";
        // case 'txt':
        //   return 'text/plain';
        case "doc":
        case "docx":
          return "word";
        case "xls":
        case "xlsx":
          return "excel";
        case "ppt":
        case "pptx":
          return "ppt";
        // case 'mp3':
        //   return 'audio/mpeg';
        // case 'mp4':
        //   return 'video/mp4';
        // case 'zip':
        //   return 'application/zip';
        case "rar":
          return "rar";
        default:
          return "unknow";
      }
    },
    uploadPathBuild() {
      const rootPath = envInfo.bgApp.archivePath;
      return `${rootPath}/param/archive/upload?ulType=DefaultUpload&scopeType=ALL`;
    },
    handleChange(file, fileList) {},
    successHandler(response, file, fileList) {
      this.uploadLoading = false;
      this.fileList = fileList;
      this.$emit("update", this.fileList);
      this.progressAnimation();
    },
    errorHandler() {
      this.uploadLoading = false;
      this.resetDatas();
    },
    beforeUpload(file) {
      console.log("xxx1", file);
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 <= 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        this.uploadLoading = false;
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.process-uploader {
  .file-list {
    margin-top: 12px;
    .top-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-span {
        display: flex;
        align-items: center;
        .size-part {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          line-height: 14px;
          .name-line {
            color: #252d3d;
          }
          .size-line {
            color: #a7b4c7;
            margin-top: 4px;
          }
        }
      }
      .el-icon-close {
        position: relative;
        top: -7px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .progress-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .outer {
        flex: 1;
        background: #e6eef5;
        border-radius: 10px;
        position: relative;
        height: 8px;
        .inner {
          transition: all 0.7s ease-in-out;
          background: #2a61ff;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
        }
      }
      .rate-part {
        margin-left: 12px;
      }
    }
  }
  .card-container {
    height: 94px;
    background: #fcfdff;
    border-radius: 10px;
    border: 1px solid #e8ebf3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 16px;
  }
  .upload-icon {
    margin-right: 4px;
  }
}
</style>